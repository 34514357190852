<template>
  <a class="containerDetailCard"
    :class="[{'selected': itemIsSelected}, {'someItemsSelected': someItemsSelected}, collectionItem.source]"
    :target="collectionItem.target"
    :href="collectionItem.url"
    @click="clickOncard()"
  >
    <genericCheckBox
      class="containerDetailCard__containerData--checkbox"
      v-if="collectionMine"
      v-model="checkboxChecked"
      @change="toggleCardSelection($event)">
    </genericCheckBox>
    <section class="containerDetailCard__containerImage"
      @click.stop.prevent="selectThumbnail(collectionItem)">
      <cardImages :item="collectionItem" :indexItem="index"></cardImages>
      <div class="containerDetailCard__containerImage--selectThumbnail" :class="{'hidden': !showThumbnailMessage}" v-if="collectionMine">
        <span class="grey-icon-camera-thumbnail"></span>
        <span class="label">Select as a thumbnail</span>
      </div>
    </section>
    <section class="containerDetailCard__containerData">
      <div class="containerDetailCard__containerData--main">
        <span class="source">{{collectionItem.sourceTitle ? collectionItem.sourceTitle : collectionItem.source}}</span>
        <span class="title box-vertical" :title="collectionItem.title">{{collectionItem.title}}</span>
        <span class="subtitle box-vertical" v-if="collectionItem.persona_title" :title="collectionItem.persona_title">{{collectionItem.persona_title}}</span>
      </div>
      <div class="containerDetailCard__containerData--bottom">
        <section class="leftData">
          <span class="leftData__agency" v-if="collectionItem.agency" :title="collectionItem.agency">{{collectionItem.agency}}</span>
          <span class="leftData__date" v-if="collectionItem.date_created">{{collectionItem.date_created}}</span>
          <span class="leftData__country" v-if="collectionItem.country" :title="collectionItem.country">{{collectionItem.country}}</span>
        </section>
        <span class="icon icon-grey-delete" title="Remove from collection" v-if="collectionMine && !someItemsSelected" @click.stop="deleteItemCollectionById"></span>
      </div>
    </section>
  </a>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import cardImages from '@/components/cardImages/cardImages'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'

export default {
  name: 'collectionDetailCard',
  props: {
    collectionItem: {
      type: Object,
      required: true
    },
    collectionMine: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    collectionId: {
      type: String,
      required: true
    },
    thumbnailChanged: {
      type: Boolean,
      required: true
    },
    itemIsSelected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    cardImages,
    genericCheckBox
  },
  emits: ['select-thumbnail', 'openHighlightModal', 'delete-from-card'],
  setup (props, { emit }) {
    const store = useStore()
    const deleteCollectionModalRef = ref(null)
    const showThumbnailMessage = ref(false)
    const checkboxChecked = ref(false)

    /**
     * @description Hides the thumbnail message after it finishes loading
     * @param {boolean} thumbnailChanged value to know if message should be hidden
     */
    watch(() => props.thumbnailChanged, (thumbnailChanged) => {
      if (thumbnailChanged) toggleThumbnailMessage(false)
    })

    /**
     * @description checks if checkbox from current card should be shown as checked
     * @param arr array of items selected
     */
    watch(() => store.state.collectionItemsIdsSelected, (arr) => {
      if (arr.includes(props.collectionItem.id)) {
        checkboxChecked.value = true
      } else {
        checkboxChecked.value = false
      }
    })

    onMounted(() => {
      setCheckbox()
    })

    function setCheckbox () {
      if (store.state.collectionItemsIdsSelected.includes(props.collectionItem.id)) {
        checkboxChecked.value = true
      } else {
        checkboxChecked.value = false
      }
    }

    /**
     * @description returns TRUE if there is at least ONE item selected
     */
    const someItemsSelected = computed(() => {
      return store.state.collectionItemsIdsSelected.length > 0
    })

    /**
     * @description Open modal to delete item from card
     * @param {event} default object
     */
    function deleteItemCollectionById (event) {
      event.stopPropagation()
      event.preventDefault()
      emit('delete-from-card', props.collectionItem.id)
    }

    /**
     * @description Emits that user seleted a thumbnail
     * @param {object} item data of the selected item
     */
    function selectThumbnail (item) {
      emit('select-thumbnail', item)
      toggleThumbnailMessage(true)
    }

    /**
     * @description Toggles visibility of thumbnail message according device
     * @param {boolean} isVisible flag to show or hide the message
     */
    function toggleThumbnailMessage (isVisible) {
      if (store.state.isMobile) showThumbnailMessage.value = isVisible
    }

    /**
     * @description handle card click event
    */
    function clickOncard () {
      if (props.collectionItem.sourceTitle === 'HIGHLIGHT') emit('openHighlightModal', { highlightId: props.collectionItem.id, title: props.collectionItem.title })
    }

    /**
     * @description Toggles card selection
     * @param {boolean} isSelected shows if card has been selected or deselected
     */
    function toggleCardSelection (isSelected) {
      event.stopPropagation()
      event.preventDefault()
      checkboxChecked.value = !checkboxChecked.value
      store.commit({
        type: 'TOGGLE_COLLECTION_ITEM_SELECTION',
        itemId: props.collectionItem.id,
        isSelected: isSelected
      })
    }

    return {
      store,
      deleteCollectionModalRef,
      deleteItemCollectionById,
      showThumbnailMessage,
      selectThumbnail,
      toggleThumbnailMessage,
      clickOncard,
      toggleCardSelection,
      checkboxChecked,
      someItemsSelected,
      setCheckbox
    }
  }
}
</script>
