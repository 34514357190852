<template>
  <div class="containerSkeletonDetailCollection">
    <div class="containerSkeletonDetailCollection__header">
      <div class="containerSkeletonDetailCollection__header--image"></div>
      <div class="containerSkeletonDetailCollection__header--primaryData">
        <div class="leftData">
          <div class="leftData__source"></div>
          <div class="leftData__title"></div>
        </div>
        <div class="rightData"></div>
      </div>
      <div class="containerSkeletonDetailCollection__header--secondaryData"></div>
    </div>
    <div class="containerSkeletonDetailCollection__body">
      <div class="containerSkeletonDetailCollection__body--card" v-for="item in items" :key="item">
        <div class="card__image"></div>
        <div class="card__data">
          <div class="card__data--source"></div>
          <div class="card__data--title"></div>
          <div class="card__data--subtitle"></div>
          <div class="card__data--agency"></div>
          <div class="card__data--date"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'skeletonDetailCollection',

  setup (props) {
    const items = ref(12)

    return {
      items
    }
  }
}
</script>
