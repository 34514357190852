<template>
  <section class="containerDetailCollection">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section class="containerDetailCollection__info">
      <backButton @goBack="goBack" altLabel="COLLECTIONS" altRedirection='collections'></backButton>
      <section class="containerDetailCollection__info--contentData" v-if="collectionLoaded && persistedVerified">
        <header class="collectionHeader">
          <figure class="collectionHeader__imageContainer">
            <img class="collectionHeader__imageContainer--image" :src="collectionData.thumbnailUrl"/>
            <div class="collectionHeader__imageContainer--upload"
              v-if="collectionData.isMine || collectionData.canEdit"
              :class="{'success': thumbnailChanged, 'loading': isThumbnailLoading}"
              @click="toggleModalVisibility('upload', true)"
              :title="tooltipTitle">
              <span class="grey-icon-success-thumbnail" v-if="thumbnailChanged"></span>
              <span class="grey-icon-upload-thumbnail" v-else-if="!isThumbnailLoading"></span>
              <spinner class="spinnerThumbnail" v-else></spinner>
            </div>
          </figure>
          <div class="collectionHeader__primaryData">
            <div class="collectionHeader__primaryData--title">
              <span class="source">{{collectionData.isPublic ? 'PUBLIC' : 'PRIVATE'}} COLLECTION</span>
              <div class="titleOptions">
                <h2 class="titleOptions__title" :title="collectionData.name">{{collectionData.name}}</h2>
                <collectionOptions
                  :hasPeopleItems="collectionData.hasPeopleItems"
                  :isPublic="collectionData.isPublic"
                  :isMine="collectionData.isMine"
                  :isEmpty="totalItems"
                  :collectionId="collectionData.id"
                  :name="collectionData.name"
                  :coOwners="collectionData.totalCoOwners"
                  :isCoOwner="collectionData.canEdit"
                  @setup-page-again="setUpPage"
                  v-if="showCollectionOptionsMobile"
                >
                </collectionOptions>
              </div>
            </div>
            <div class="collectionHeader__primaryData--authorOptions">
              <collectionOptions
                :hasPeopleItems="collectionData.hasPeopleItems"
                :isPublic="collectionData.isPublic"
                :isMine="collectionData.isMine"
                :isEmpty="totalItems"
                :collectionId="collectionData.id"
                :name="collectionData.name"
                :coOwners="collectionData.totalCoOwners"
                :isCoOwner="collectionData.canEdit"
                @setup-page-again="setUpPage"
                v-if="!showCollectionOptionsMobile"
              >
              </collectionOptions>
              <span v-if="store.state.managerInfo.title || collectionData.userName" class="author"
                :class="{noCoOwners : !store.state.isMobileResolution && collectionData.totalCoOwners == 0}"
                @mouseover="openPopupPersonalInfo($event, collectionData.displayUserInfo)"
                @mouseleave="openPopupPersonalInfo($event, false)">
                  <span :class="collectionData.displayUserInfo ? 'author--name' : 'authorTerminated--name'"
                  @click="collectionData.displayUserInfo ? goToProfile({id: collectionData.userId}) : ''"
                  :id="collectionData.userId ? 'link': ''"
                  >
                    <span id="collectionOwner--prefix">By </span>
                    {{ collectionData.displayUserInfo && store.state.managerInfo.title ? store.state.managerInfo.title : collectionData.userName }}
                  </span>
                <popupPersonalInfoGeneric
                  v-if="openPopup && collectionData.displayUserInfo && store.state.managerInfo.id"
                  :popupPosition="popupPosition"
                  :personalInfoProp="store.state.managerInfo"
                  :profileLink="profileLink"
                  :isSearchApp="true"
                  @click.stop.prevent
                />
              </span>
              <div class="collectionCoOwners__container" v-if="collectionData.totalCoOwners > 0">
                <span class="collectionCoOwners__container--title">
                  Co-Owner:
                </span>
                <span v-for="(coowner, index) in collectionData.coOwners" :key="index" class="coowner">
                  <img
                    class="coowner__image"
                    :title="coowner.name"
                    :src="thumbnailCoOwner(coowner)"
                    @click="goToProfile(coowner)"
                  />
                </span>
                <span
                  class="grey-icon-show-more"
                  title="View All"
                  @click="toggleCoOwnersModal(true)"
                  />
              </div>
            </div>
            </div>
          <div class="collectionHeader__secondaryData">
              <span class="collectionHeader__secondaryData--date">{{collectionData.createdDate}}</span>
          </div>
        </header>
        <section class="collectionBody" :class="{'noItems': !totalItems}">
          <selectAllCollectionItems
            ref="selectAll"
            v-if="(collectionData.isMine || collectionData.canEdit) && collectionData.items.length > 0"
            @delete="toggleDeleteModal(true)"
            />
          <section class="collectionBody__noItemsMessage" v-if="!totalItems">
              <img class="collectionBody__noItemsMessage--image" src="@/assets/no-collections.svg"/>
            <span class="collectionBody__noItemsMessage--label">This collection has no items.</span>
          </section>
          <ul v-else class="collectionBody__cards">
            <li v-for="(collectionItem, index) in collectionItems" :key="index" :data-index="index" class="collectionBody__cards--card">
              <collectionDetailCard
                :collectionItem="collectionItem"
                :collectionMine="collectionData.isMine || collectionData.canEdit"
                :collectionId="collectionData.id"
                :index="index"
                :thumbnailChanged="thumbnailChanged"
                :itemIsSelected="itemIsSelected(collectionItem.id)"
                @select-thumbnail="selectThumbnail"
                @openHighlightModal="toggleHighLightModal(true, $event)"
                @delete-from-card="deleteItemFromCard"
              >
              </collectionDetailCard>
            </li>
          </ul>
          <paginator
            v-if="totalItems > 48"
            :totalItems="totalItems"
            :maxPages="maxPages"
            :pagination="store.state.collectionPagination"
            @goToPage="goToPage">
          </paginator>
        </section>
      </section>
      <skeletonDetailCollection v-else></skeletonDetailCollection>
      <genericModalLogic
        theme="gray"
        v-if="isUploadModalOpen"
        class="uploadThumbnailModal"
        title="Upload Thumbnail"
        @closeModal="toggleModalVisibility('upload', false)">
        <uploadCollectionThumbnail @upload="uploadedThumbnail($event)"></uploadCollectionThumbnail>
      </genericModalLogic>
      <genericModalLogic
        theme="gray"
        v-if="isSaveModalOpen"
        class="saveThumbnailModal"
        title="Preview Thumbnail"
        @closeModal="toggleModalVisibility('save', false)">
        <saveCollectionThumbnail :image="imageUploaded" @back="toggleModalVisibility('upload', true)" @saved="savedThumbnail"></saveCollectionThumbnail>
      </genericModalLogic>
      <peopleListModalGeneric v-if="coOwnersModalVisible" listType="coOwners" :itemId="collectionData.id" @close="toggleCoOwnersModal(false)"></peopleListModalGeneric>
      <toast v-if="shareToast" :class="{'marginToolToast': !store.state.isMobile}" :message="shareToastMessage"></toast>
      <highLightModalGeneric
        v-if="highLightModalVisible"
        requestHighlight
        :highlightId="highLightObject.highlightId"
        application="Search"
        :isHighLightModalMobile="store.state.isHighLightModalMobile"
        @close="toggleHighLightModal(false)">
      </highLightModalGeneric>
    </section>
    <genericModal
      v-if="showDeleteModal"
      :title="itemsSelectedAmount === 1 ? 'Delete Item' : 'Delete Items'"
      :message="itemsSelectedAmount === 1 ? 'Are you sure you want to delete this item?' : 'Are you sure you want to delete the selected items?'"
      :cancelText="'Cancel'"
      :acceptText="'Delete'"
      @close="toggleDeleteModal(false)"
      @cancel="toggleDeleteModal(false)"
      @accept="handleDeleteItems" />
  </section>
</template>

<script>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import backButton from '@/components/backButton/backButton'
import paginator from '@/components/paginator/paginator'
import skeletonDetailCollection from '@/components/skeletons/skeletonDetailCollection/skeletonDetailCollection'
import spinner from '@/components/spinner/spinner'
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import uploadCollectionThumbnail from '@/components/uploadCollectionThumbnail/uploadCollectionThumbnail'
import saveCollectionThumbnail from '@/components/saveCollectionThumbnail/saveCollectionThumbnail'
import messages from '../../messages.json'
import collectionOptions from '../../components/collectionOptions/collectionOptions.vue'
import collectionDetailCard from '../../components/collectionDetailCard/collectionDetailCard.vue'
import selectAllCollectionItems from '@/components/selectAllCollectionItems/selectAllCollectionItems'
import genericModal from '@/components/genericModal/genericModal'
import $ from 'jquery'

export default {
  name: 'detailCollectionPage',
  components: {
    backButton,
    paginator,
    skeletonDetailCollection,
    spinner,
    genericModalLogic,
    uploadCollectionThumbnail,
    saveCollectionThumbnail,
    collectionOptions,
    collectionDetailCard,
    selectAllCollectionItems,
    genericModal
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const collectionData = ref()
    const collectionItems = ref([])
    const maxPages = ref(6)
    const totalItems = ref()
    const collectionLoaded = ref(false)
    const isThumbnailLoading = ref(false)
    const thumbnailChanged = ref(false)
    const isUploadModalOpen = ref(false)
    const isSaveModalOpen = ref(false)
    const imageUploaded = ref(null)
    const shareToast = ref(false)
    const shareToastMessage = ref(messages.generic.shareToast)
    const showCollectionOptionsMobile = ref(null)
    const coOwnersModalVisible = ref(false)
    const openPopup = ref(false)
    const popupPosition = reactive({
      right: 0,
      top: 0,
      bottom: 0,
      left: 0
    })
    const requestControl = ref(null)
    const highLightModalVisible = ref(false)
    const highLightObject = ref({})
    const persistedVerified = ref(false)
    const showDeleteModal = ref(false)
    const scrollY = ref(0)

    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
        persistedVerified.value = false
      } else {
        persistedVerified.value = true
      }
    }

    /**
     * @description set title of the tooltip
     */
    const tooltipTitle = computed(() => {
      return !thumbnailChanged.value && !isThumbnailLoading.value ? 'Upload thumbnail' : ''
    })

    /**
     * @description Get profile picture
     * @param thumbnailUrl url of the thumbnail
     */
    const thumbnailCoOwner = computed(() => {
      return coOwner => coOwner.displayUserInfo ? coOwner.thumbnail : require('../../assets/avatar.jpg')
    })

    /**
     * @description Get user profile link
     */
    const profileLink = computed(() => {
      return process.env.VUE_APP_NODE_ENV === 'prd' ? `https://people.grey.com/profile/${collectionData.value.userId}?pageToken=${localStorage.getItem('sessionToken')}` : `https://${process.env.VUE_APP_NODE_ENV}-people.grey.com/profile/${collectionData.value.userId}?pageToken=${localStorage.getItem('sessionToken')}`
    })

    /**
     * @description returns TRUE if given item by id is selected
     * @param itemId item ID
     */
    const itemIsSelected = computed(() => (itemId) => {
      return store.state.collectionItemsIdsSelected.includes(itemId)
    })

    /**
     * @description amount of items selected
     */
    const itemsSelectedAmount = computed(() => {
      return store.state.collectionItemsIdsSelected.length
    })

    onMounted(() => {
      window.onpopstate = function () {
        setUpPage()
      }
      setUpPage()
      resizeSetUp()
      handleResize()
      setBackToTopButton()
    })

    function setBackToTopButton () {
      let mainSpace
      if (window.innerWidth < 1024) {
        mainSpace = document.querySelector('.topSpaceMobile')
      } else {
        mainSpace = document.querySelector('.leftSpaceDesktop')
      }
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    function scrollToTop () {
      $('html, #app').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description Sets page pagination and loads data
     */
    function setUpPage () {
      updateStorePagination(route.query.k, route.query.t)
      goToPage(1)
    }

    /**
     * @description Sets values of pagination in the store.
     * @param pageParam number of current page.
     * @param sizeParam number of cards per page.
     */
    function updateStorePagination (pageParam, sizeParam) {
      store.commit({
        type: 'SET_COLLECTION_PAGINATION',
        page: pageParam,
        size: sizeParam
      })
    }

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Sets maxPages value according to screen size
    */
    function resizeSetUp () {
      showCollectionOptionsMobile.value = window.innerWidth <= 1024
      if ((window.innerWidth <= 576) && (window.innerWidth > 0)) {
        maxPages.value = 3
      } else {
        maxPages.value = 6
      }
    }

    /**
     * @description Execute the action required when a new page is selected
     * @param pageNumber Number of the page selected
     */
    function goToPage (pageNumber) {
      document.querySelector('#app #app').scrollTo(0, 0)
      updateStorePagination(pageNumber, 48)
      store.commit({
        type: 'UPDATE_DETAIL_COLLECTION_URL',
        id: route.params.idCard
      })
      requestCollectionData()
    }

    /**
     * @description Dispatches a method in the store to get collection data
     */
    function requestCollectionData () {
      collectionLoaded.value = false
      store.dispatch({
        type: 'getCollectionDetails',
        id: route.params.idCard
      }).then(
        (response) => {
          response.createdDate = store.getters.formatDate(response.createdDate)
          response.userName = store.getters.formatAuthorName(response.userName)
          store.state.collectionItemsIds = response.itemIds
          totalItems.value = response.count
          collectionData.value = response
          collectionItems.value = parseItemsResponse(response.items)
          store.dispatch({
            type: 'getManagerData',
            ecCode: collectionData.value.userId,
            namePositionNumber: 0
          }).then(() => {
            collectionLoaded.value = true
            showToastMessage()
          })
        }
      )
    }

    /**
     * @description Parses response from API to a generic object with standarized properties to
     * be used by card.
     *  @param {Array} items Array of collection items to be parsed
     */
    function parseItemsResponse (items) {
      const response = items.map((response) => {
        response.date_created = store.getters.formatDate(Date.parse(response.date_created))
        response.agency = response.agency.join(', ')
        response.target = response.source === 'sharepoint' ? '_blank' : '_self'

        if (response.source === 'people') {
          response.country = response.country[0]
          response.date_created = null
        } else if (response.source === 'sharepoint') {
          response.sourceTitle = 'Office 365'
          response.agency = null
        } else if (response.source === 'gogrey') {
          switch (response.internal_category) {
            case 'Opportunity':
              response.sourceTitle = 'Borderless Opportunity'
              break
            case 'Article':
              response.sourceTitle = 'Go Grey News'
              break
            case 'Highlight':
              response.sourceTitle = 'HIGHLIGHT'
              response.url = null
              break
            case 'Spotlight':
              response.sourceTitle = 'OFFICE SPOTLIGHT'
              break
          }
        }
        return response
      })
      return response
    }

    /**
     * @description Toggles the modal visibility.
     * @param {String} modalToToggle the modal to toggle.
     * @param {Boolean} isVisible the visibility state expected.
     */
    function toggleModalVisibility (modalToToggle, isVisible) {
      if (modalToToggle === 'upload') {
        if (!isThumbnailLoading.value && !thumbnailChanged.value) {
          isSaveModalOpen.value = false
          isUploadModalOpen.value = isVisible
        }
      } else {
        isUploadModalOpen.value = false
        isSaveModalOpen.value = isVisible
      }
    }

    /**
     * @description Execute the actions required when a collection thumbnail is selected from cards
     * @param {Object} event DOM event
     * @param {Object} item item selected to be collection thumbnail
     */
    function selectThumbnail (item) {
      if (collectionData.value.isMine || collectionData.value.canEdit) {
        setIconsVisibility('loading')
        store.dispatch({
          type: 'collections/selectCollectionThumbnail',
          collectionId: collectionData.value.id,
          itemId: item.id
        }).then(
          () => {
            setIconsVisibility()
            collectionData.value.thumbnailUrl = item.thumbnail_url
          })
      }
    }

    /**
     * @description Opens modal to show image uploaded preview.
     * @param {Object} image image to show.
     */
    function uploadedThumbnail (image) {
      imageUploaded.value = image
      toggleModalVisibility('save', true)
    }

    /**
     * @description Execute the actions required when a collection thumbnail is uploaded.
     */
    function savedThumbnail () {
      toggleModalVisibility('save', false)
      setIconsVisibility('loading')
      store.dispatch({
        type: 'uploadCollectionThumbnail',
        collectionId: collectionData.value.id
      }).then(
        () => {
          setIconsVisibility()
          collectionData.value.thumbnailUrl = imageUploaded.value.src
        })
    }

    /**
     * @description Sets icons visibility according request status
     * @param {String} status of request
     */
    function setIconsVisibility (status) {
      if (status === 'loading') {
        thumbnailChanged.value = false
        isThumbnailLoading.value = true
      } else {
        isThumbnailLoading.value = false
        thumbnailChanged.value = true
        setTimeout(() => {
          thumbnailChanged.value = false
        }, 3000)
      }
    }

    /**
     * @description Shows share message when accesing url with sharing token
     */
    function showToastMessage () {
      if (store.state.sharingToken) {
        shareToast.value = true
        setTimeout(() => {
          shareToast.value = false
          store.commit({
            type: 'SET_SHARING_TOKEN',
            sharingToken: ''
          })
        }, 5000)
      }
    }

    /**
     * @description Sets local store flag to indicate if restore or not scroll position
     */
    function setScrollRestoreFlag () {
      localStorage.setItem('restoreScrollTop', true)
    }

    /**
     * @description Actions on back click
     */
    function goBack () {
      setScrollRestoreFlag()
    }

    /**
     * @description Open user profile page
     * @param user selected user
     */
    function goToProfile (user) {
      if (user.id) {
        window.open(`${process.env.VUE_APP_PF_URL}profile/${user.id}?pageToken=${localStorage.getItem('sessionToken')}`, '_self')
      }
    }

    /**
     * @description Open/Close Co-owners modal
     * @param flag flag to indicate if open or close
     */
    function toggleCoOwnersModal (flag) {
      coOwnersModalVisible.value = flag
    }

    /**
     * @description Open and positioned popup depending the size of the screen
     * @param event of DOM
     * @param {Bool} flag to show or hide modal
     */
    function openPopupPersonalInfo (event, flag) {
      const link = document.getElementById('link')

      if (!link) {
        return
      }
      const windowWidth = window.innerWidth
      if (!navigator.userAgent.match(/Android|iPhone|iPad/i)) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          if (windowWidth > 1240) {
            popupPosition.left = '61%'
            popupPosition.top = event.clientY / 2 + 'px'
          } else {
            popupPosition.left = '50%'
            popupPosition.top = event.clientY / 2 + 'px'
          }
          openPopup.value = flag
        }, 500)
      }
    }

    /**
     * @description Open/Close highLight modal
     * @param flag flag to indicate if open or close
     * @param highLightParam active highLight
     */
    function toggleHighLightModal (flag, highLightParam = {}) {
      if (flag) {
        highLightObject.value = highLightParam
        highLightModalVisible.value = true
      } else {
        highLightModalVisible.value = false
      }
    }

    /**
     * @description Open/Close delete modal
     * @param value flag to indicate if open or close
     */
    function toggleDeleteModal (value) {
      showDeleteModal.value = value
    }

    /**
     * @description deletes selected items
     */
    function handleDeleteItems () {
      showDeleteModal.value = false
      collectionLoaded.value = false
      store.dispatch({
        type: 'deleteSelectedItems',
        collectionId: collectionData.value.id
      }).then(
        (response) => {
          setUpPage()
        })
    }

    /**
     * @description deletes given item by id from trash icon inside card
     * @param id item id
     */
    function deleteItemFromCard (id) {
      store.state.collectionItemsIdsSelected = [id]
      showDeleteModal.value = true
    }

    return {
      store,
      collectionData,
      collectionItems,
      maxPages,
      goToPage,
      totalItems,
      collectionLoaded,
      selectThumbnail,
      isThumbnailLoading,
      thumbnailChanged,
      isUploadModalOpen,
      isSaveModalOpen,
      toggleModalVisibility,
      uploadedThumbnail,
      imageUploaded,
      savedThumbnail,
      shareToast,
      shareToastMessage,
      tooltipTitle,
      setUpPage,
      showCollectionOptionsMobile,
      goBack,
      goToProfile,
      toggleCoOwnersModal,
      coOwnersModalVisible,
      thumbnailCoOwner,
      openPopupPersonalInfo,
      openPopup,
      popupPosition,
      requestControl,
      profileLink,
      highLightModalVisible,
      highLightObject,
      toggleHighLightModal,
      persistedVerified,
      itemIsSelected,
      toggleDeleteModal,
      showDeleteModal,
      handleDeleteItems,
      itemsSelectedAmount,
      deleteItemFromCard,
      scrollToTop,
      scrollY
    }
  }
}
</script>
