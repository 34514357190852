<template>
  <section @click.stop="toggleSelection" class="containerGenericCheck">
    <input type="checkbox" :checked="modelValue" class="containerGenericCheck__input" ref="inputRef" :class="{'showTick': showTick}"/>
    <label class="containerGenericCheck__label">{{label}}</label>
    <slot></slot>
  </section>
</template>

<script>
import { watch, ref, onMounted } from 'vue'
export default {
  name: 'genericCheckBox',
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    indeterminate: {
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    showTick: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  setup (props, { emit }) {
    const inputRef = ref(null)

    onMounted(() => {
      inputRef.value.indeterminate = props.indeterminate
    })

    /**
     * @description Emits an event to notify the selection has changed.
     */
    function toggleSelection () {
      emit('change', !props.modelValue)
      emit('update:modelValue', !props.modelValue)
    }

    /**
     * @description Sets the value of indeterminate property on the input.
     * @param {isIndeterminate} boolean to know the new value of indeterminate property on the input.
     */
    watch(() => props.indeterminate, (isIndeterminate) => {
      inputRef.value.indeterminate = isIndeterminate
    })

    return {
      toggleSelection,
      inputRef
    }
  }
}
</script>
