<template>
  <section class="containerBackButton">
    <div class="containerBackButton__contentBack" @click="goBack">
      <span v-if="isBackCase" class="grey-icon-arrow-back containerBackButton__contentBack--icon"></span>
      <span class="containerBackButton__contentBack--label" :class="{'goHome': !isBackCase}">{{backLabel}}</span>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'backButton',
  props: {
    altRedirection: {
      type: String,
      default: 'home'
    },
    altLabel: {
      type: String
    }
  },
  emits: ['goBack'],
  setup (props, { emit }) {
    const router = useRouter()

    /**
     * @description Go to preview page if there is document.referrer, else go to home page
     */
    function goBack () {
      emit('goBack')
      if (document.referrer) {
        var prevPage = window.location.href
        window.history.go(-1)
        setTimeout(function () {
          if (window.location.href === prevPage) {
            router.push({ name: props.altRedirection })
          }
        }, 500)
      } else {
        router.push({ name: props.altRedirection })
      }
    }

    /**
     * @description Set detail page back label depending on previous state
     */
    const backLabel = computed(() => {
      if (props.altLabel) {
        return previousState.value ? 'Back' : props.altLabel
      } else {
        return 'Back'
      }
    })

    /**
     * @description Ckeck if is back label
     */
    const isBackCase = computed(() => {
      return backLabel.value.toLowerCase() === 'back'
    })

    /**
     * @description Return previous state if applies
     */
    const previousState = computed(() => {
      return (document.referrer && !document.referrer.includes('?token=') ? window.history.length > 1 : false)
    })
    return {
      router,
      goBack,
      backLabel,
      previousState,
      isBackCase
    }
  }
}
</script>
