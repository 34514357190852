<template>
  <section class="containerGenericModal__wrapper">
    <div class="containerGenericModal__background" @click.stop.prevent="close"></div>
    <section class="containerGenericModal__mainContainer">
      <header class="containerGenericModal__header">
        <span class="containerGenericModal__header--title">{{title}}</span>
        <span class="containerGenericModal__header--closeBtn grey-icon-close" @click="close"></span>
      </header>
      <span class="containerGenericModal__message">{{ message }}</span>
      <section class="containerGenericModal__buttons">
        <button class="containerGenericModal__buttons--cancelBtn" @click="cancel" v-if="cancelText">{{cancelText}}</button>
        <button class="containerGenericModal__buttons--acceptBtn" @click="accept" v-if="acceptText">{{acceptText}}</button>
      </section>
    </section>
  </section>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  props: ['title', 'message', 'cancelText', 'acceptText'],
  emits: ['close', 'cancel', 'accept'],
  setup (props, { emit }) {
    onMounted(() => {
      document.addEventListener('keydown', closeOnKeyPressed)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', closeOnKeyPressed)
    })

    /**
     * @description Sets event to close the modal when the 'esc' key is pressed
     */
    function closeOnKeyPressed () {
      if (event.key === 'Escape' && window.innerWidth > 1024) close()
    }

    /**
     * @description Emits close event rised by close button.
     */
    function close () {
      emit('close')
    }

    /**
     * @description Emits cancel event rised by cancel button.
     */
    function cancel () {
      emit('cancel')
    }

    /**
     * @description Emits accept event rised by accept button.
     */
    function accept () {
      emit('accept')
    }

    return {
      close,
      cancel,
      accept
    }
  }
}
</script>
