<template>
  <nav class="containerPaginator">
    <ul class="containerPaginator__paginator" v-if="pagesToShow.length > 1">
      <li class="containerPaginator__paginator--page">
        <a class="link"
           @click="goToFirstPage()"
           :class="{'linkHidden': isActiveFirstPage}">
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
        </a>
      </li>
      <li class="containerPaginator__paginator--page">
        <a class="link"
           @click="goToPreviousPage()"
           :class="{'linkHidden': isActiveFirstPage}">
          <span class="grey-icon-arrow-pagination link__arrowLink"></span>
        </a>
      </li>
      <li class="containerPaginator__paginator--page" v-for="pageNumber in pagesToShow" :key="pageNumber">
        <a class="link number"
           @click="goToPage(pageNumber)"
           :class="{'activeLink': pageNumber === actualPage}">
          {{pageNumber}}
        </a>
      </li>
      <li class="containerPaginator__paginator--page">
        <a class="link lastLink"
           @click="goToNextPage()"
           :class="{'linkHidden': isActiveLastPage}">
          <span class="grey-icon-arrow-pagination link__arrowLink"></span>
        </a>
      </li>
      <li class="containerPaginator__paginator--page">
        <a class="link lastLink"
           @click="goToLastPage()"
           :class="{'linkHidden': isActiveLastPage}">
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { onMounted, computed, watch, ref } from 'vue'

export default {
  name: 'paginator',
  props: ['totalItems', 'maxPages', 'pagination'],
  emits: ['goToPage'],
  setup (props, { emit }) {
    const actualPage = ref(1)
    const pagesToShow = ref([])

    /**
     * @description Caluclates the total number of pages to show.
     */
    const totalPages = computed(() => {
      return Math.ceil(props.totalItems / props.pagination.size)
    })

    /**
     * @description Returns a flag indicating if first page is active.
     */
    const isActiveFirstPage = computed(() => {
      return actualPage.value === 1
    })

    /**
     * @description Returns a flag indicating if last page is active.
     */
    const isActiveLastPage = computed(() => {
      return actualPage.value === totalPages.value
    })

    onMounted(() => {
      actualPage.value = Number(props.pagination.page)
      setPagesToShow()
    })

    /**
     * @description Detect when maxpagestoshow property change
     */
    watch(() => props.maxPages, (maxPages) => {
      setPagesToShow()
    })

    /**
     * @description Sets pages to show on the UI.
     */
    function setPagesToShow () {
      pagesToShow.value = getpagesToShow()
    }

    /**
     * @description Gets array of pages based on total pages, max number of pages and total of items.
     */
    function getpagesToShow () {
      if (isNaN(totalPages.value) || !totalPages.value || !props.maxPages || totalPages.value === 1) {
        return []
      }

      if (totalPages.value <= props.maxPages) {
        return getNumeredArray(totalPages.value)
      }

      return getCenteredPages()
    }

    /**
     * @description Returns an array of number increase order, from 1 to given total of elements,
     * i.e. given 10 it returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
     * @param totalItems max number reached inside of array.
     */
    function getNumeredArray (totalItems) {
      return [...Array.from({ length: totalItems }, (v, i) => ++i)]
    }

    /**
     * @description Gets an array of pages where current page is centered.
     */
    function getCenteredPages () {
      if (actualPage.value <= Math.ceil(props.maxPages / 2)) {
        return getNumeredArray(props.maxPages)
      }

      let pages
      if (props.maxPages % 2 === 0) {
        pages = getNumeredArray(actualPage.value + Math.floor(props.maxPages / 2) - 1).slice(-(props.maxPages))
      } else {
        pages = getNumeredArray(actualPage.value + Math.floor(props.maxPages / 2)).slice(-(props.maxPages))
      }

      if (pages[pages.length - 1] <= totalPages.value) {
        return pages
      } else {
        return getNumeredArray(totalPages.value).slice(-(props.maxPages))
      }
    }

    /**
     * @description Moves page to previous one.
     */
    function goToPreviousPage () {
      goToPage(actualPage.value - 1)
    }

    /**
     * @description Moves page to next one.
     */
    function goToNextPage () {
      goToPage(actualPage.value + 1)
    }

    /**
     * @description Moves page to first one.
     */
    function goToFirstPage () {
      goToPage(1)
    }

    /**
     * @description Moves page to last one.
     */
    function goToLastPage () {
      goToPage(totalPages.value)
    }

    /**
     * @description Moves pagination to any given page.
     * @param pageNumber number of page to move the pagination to.
     */
    function goToPage (pageNumber) {
      emit('goToPage', pageNumber)
      document.querySelector('#app #app').scrollTo(0, 0)
    }

    return {
      actualPage,
      pagesToShow,
      totalPages,
      isActiveFirstPage,
      isActiveLastPage,
      goToPreviousPage,
      goToNextPage,
      goToPage,
      goToFirstPage,
      goToLastPage
    }
  }
}
</script>
