<template>
  <section class="containerSaveThumbnail">
    <section class="containerSaveThumbnail__imageContent">
      <img :src="image.src" class="containerSaveThumbnail__imageContent--image">
      <span class="containerSaveThumbnail__imageContent--label">PREVIEW</span>
    </section>
    <section class="containerSaveThumbnail__buttons">
      <button class="containerSaveThumbnail__buttons--back" @click="back">BACK</button>
      <button class="containerSaveThumbnail__buttons--save" @click="save">SAVE</button>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'saveCollectionThumbnail',
  props: {
    image: {
      required: true
    }
  },
  emits: ['back', 'saved'],
  setup (props, { emit }) {
    const store = useStore()
    /**
     * @description Back previuos step (User get to upload image again)
     */
    function back () {
      emit('back')
    }

    /**
     * @description Emits that the image was saved
     */
    function save () {
      emit('saved')
    }

    return {
      store,
      back,
      save
    }
  }
}
</script>
