<template>
  <div class="containerSelectAllCollectionItems">
    <genericCheckBox v-model="modelIsSelected" @change="toggleAllArticlesSelection($event)" :indeterminate="isIndeterminate" ref="selectAllCheck" label="Select All"></genericCheckBox>
    <span class="containerSelectAllCollectionItems__itemsSelected" v-if="itemsSelectedAmount > 0">({{ itemsSelectedAmount }} {{ itemsSelectedAmount === 1 ? 'Item' : 'Items'}} selected)</span>
    <div class="containerSelectAllCollectionItems__deleteButton" v-if="itemsSelectedAmount > 0" @click="handleDelete">
      <span class="grey-icon-delete-notification containerSelectAllCollectionItems__deleteButton--icon"></span>
      <span class="containerSelectAllCollectionItems__deleteButton--label">Delete</span>
    </div>
    <section class="containerSelectAllCollectionItems__mobileModal" :style="{height: [itemsSelectedAmount > 0 ? '53px' : '0px']}">
      <span class="containerSelectAllCollectionItems__mobileModal--counter">{{itemsSelectedAmount}} Item<span v-if="itemsSelectedAmount !== 1">s</span> selected</span>
      <span class="containerSelectAllCollectionItems__mobileModal--separator">|</span>
      <span class="grey-icon-delete-notification containerSelectAllCollectionItems__mobileModal--icon" @click="handleDelete"></span>
      <span class="grey-icon-close containerSelectAllCollectionItems__mobileModal--closeButton" @click="undoSelection"></span>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'

export default {
  name: 'selectAllCollectionItems',
  components: {
    genericCheckBox
  },
  emits: ['delete'],
  setup (props, { emit }) {
    const store = useStore()
    const modelIsSelected = ref(false)

    /**
     * @description checks if checkbox from current card should be shown as checked
     * @param val amount of itemss selected
     */
    watch(() => store.state.collectionItemsIdsSelected.length, (val) => {
      if (val === store.state.collectionItemsIds.length) {
        modelIsSelected.value = true
      } else {
        modelIsSelected.value = false
      }
      document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = val > 0 ? '68px' : '34px'
    })

    onMounted(() => {
      setCheckbox()
    })

    /**
     * @description returns TRUE if "select all" checkbox should be shown in its indeterminate state
     */
    const isIndeterminate = computed(() => {
      return (store.state.collectionItemsIds.length !== store.state.collectionItemsIdsSelected.length) && store.state.collectionItemsIdsSelected.length > 0
    })

    /**
     * @description amount of items selected
     */
    const itemsSelectedAmount = computed(() => {
      return store.state.collectionItemsIdsSelected.length
    })

    function setCheckbox () {
      modelIsSelected.value = (store.state.collectionItemsIds.length === store.state.collectionItemsIdsSelected.length) && store.state.collectionItemsIdsSelected !== 0
    }

    /**
     * @description Selects or deselects all the notifications.
     * @param {areAllNotificationsSelected} boolean to indicate if all notifications will be selected or not.
     */
    function toggleAllArticlesSelection (areAllItemsSelected) {
      store.commit({
        type: 'TOGGLE_ALL_COLLECTION_ITEMS_SELECTION',
        areAllItemsSelected: areAllItemsSelected
      })
    }

    /**
     * @description emits 'delete' message when delete button is clicked
     */
    function handleDelete () {
      emit('delete')
    }

    /**
     * @description deselects all items that are currently selected
     */
    function undoSelection () {
      toggleAllArticlesSelection(false)
      modelIsSelected.value = false
    }

    return {
      modelIsSelected,
      toggleAllArticlesSelection,
      isIndeterminate,
      itemsSelectedAmount,
      handleDelete,
      undoSelection,
      setCheckbox
    }
  }
}
</script>

<style>

</style>
